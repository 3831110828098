import React from "react";
import { Link } from "gatsby";

const ListTopic = ({ topicId, topic, def }) => (
  <div className="g-topic">
    <Link to={`/glossary/${topicId}`}>
      <h4>
        <span>
          <span className="angle-double-right__icon angle-double-right"></span>
        </span>
        <span className="g-topic__title g-topic__title--color-black">
          {topic}
        </span>
      </h4>
    </Link>
    <div className="g-topic__def-div">
      <div className="g-topic__def">
        <h6>{def}</h6>
      </div>
      <div className="g-topic__read-more g-topic__read-more--red">
        <h6>
          <Link to={`/glossary/${topicId}`}>
            <span className="read-more__span">
              Read more
              <span className="angle-double-right__icon angle-double-right"></span>
            </span>
          </Link>
        </h6>
      </div>
    </div>
  </div>
);

export default ListTopic;
