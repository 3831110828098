import React from "react";
import { Link } from "gatsby";
import ListTopic from "./list-topic";

const SubjectList = ({ bgColor, singleSubject, topics }) => (
  <div className={`g-subject g-subject--bg-${bgColor}`}>
    <div className="container">
      <div className="g-subject__head div--text-center">
        <Link to={`/glossary/${singleSubject.subjectId}`}>
          <div className="g-subject_title">
            <div className={`subject-details ${singleSubject.icon}`}></div>
            <h2 className="g-subject__title g-subject__title--color-black g-subject__title--font">
              {singleSubject.subject}
            </h2>
          </div>
        </Link>
      </div>
      <div className="g-subject__explanation">
        <p className="g-subject__explanation--font">{singleSubject.def}</p>
      </div>
      <div className="g-subject__list">
        {topics.map((topic, i) => (
          <ListTopic key={i} {...topic} />
        ))}
      </div>
      <div className="div--text-center">
        <Link to={`/glossary/${singleSubject.subjectId}`}>
          <button className="btn g-subject__find-more-btn">
            Learn more about {singleSubject.subject}
          </button>
        </Link>
      </div>
    </div>
  </div>
);

export default SubjectList;
