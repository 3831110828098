import React from "react";
import Helmet from "react-helmet";
import GuideMainHeader from "./guide-components/header";
import SubjectList from "./guide-components/subject-list";
import SideNavbar from "./side-navbar";
import topicContent from "./content/all-topics.json";
import subjectContent from "./content/all-subjects.json";
import "./styles/main.scss";
import "./styles/side-navbar.scss";

const GuideMain = () => {
  return (
    <div className="guide">
      <Helmet
        title={`VdoCipher Glossary`}
        meta={[
          {
            name: "description",
            content:
              "Glossary for video streaming technologies, website and mobile technologies, video in OTT entertainment and education, video production methodology, and SVOD, AVOD and TVOD business models",
          },
          { property: "og:title", content: "Video Streaming Glossary" },
          { property: "og:type", content: "website" },
          { name: "twitter:card", content: "summary" },
          { name: "twitter:site", content: "@vdocipher" },
        ]}
      />
      <GuideMainHeader subjectContent={subjectContent} />
      <div className="g-body">
        <SideNavbar subjectContent={subjectContent} />
        <div className={`g-all-subjects`}>
          {subjectContent.map((singleSubject, i) => (
            <SubjectList
              key={i}
              bgColor={i % 2 ? "aqua" : "white"}
              singleSubject={singleSubject}
              topics={topicContent.filter((topic) =>
                singleSubject.topicsMain.includes(topic.topicId)
              )}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default GuideMain;
