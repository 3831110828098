import React from "react";
import GuideMain from "../components/guide/main-page";
import Layout from "../templates/layout";

const Glossary = () => (
  <Layout>
    <GuideMain />
  </Layout>
);

export default Glossary;
