import React from "react";
import { Link } from "gatsby";

const GuideMainHeader = ({ subjectContent }) => (
  <div>
    <div className="g-header g-header--bg-green">
      <div className="container ">
        <div className="g-header__title h1--text-center">
          <h1 className="g-header__title--h1">Your Guide to Online Video</h1>
        </div>
        <div className="g-header__subtitle h3--text-center">
          <h3 className="g-header__subtitle--h3">
            Simple explanations of Video Streaming concepts
          </h3>
        </div>
        <div className="g-header__card-container">
          {subjectContent.map((subject, i) => (
            <GuideCard
              key={i}
              subjectId={subject.subjectId}
              icon={subject.icon}
              subject={subject.subject}
            />
          ))}
        </div>
      </div>
    </div>
  </div>
);

const GuideCard = ({ subjectId, icon, subject }) => {
  return (
    <div className="g-header__card g-header__card--width-responsive">
      <Link
        className="g-header__card--link-white"
        to={`/glossary/${subjectId}`}
      >
        <div className="g-header__card_logo">
          <span className={icon}></span>
          <h3 className="g-header__card-text--padding-20">{subject}</h3>
        </div>
      </Link>
    </div>
  );
};
export default GuideMainHeader;
